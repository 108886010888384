import {
  Button,
  Grid,
  makeStyles,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import React from "react"
import Layout from "../components/Layout"
import customTheme from "../gatsby-theme-material-ui-top-layout/customTheme"

const useStyles = makeStyles(theme => ({
  sectionSeperator: {
    marginBottom: "8rem",
  },
  sectionSeperatorShadowed: {
    borderRadius: "100%",
    margin: "6rem auto 8rem",
    boxShadow: "0 14px 0px -6px #660000",
    width: "40%",
    height: "1.4rem",
  },
  title: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
  },
  paragraph: {
    fontSize: "1.8rem",
    lineHeight: "2.4rem",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "2rem",
      fontSize: "1.6rem",
    },
  },
  list: {
    textAlign: "right",
    fontSize: "1.8rem",
    listStyle: "none",
    counterReset: "li",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
    "& li::before": {
      content: "counter(li)",
      color: "#ff3300",
      display: "inline-block",
      borderRadius: "3px",
      width: "1em",
      marginLeft: ".5em",
      marginRight: "-.5em",
      textAlign: "left",
      direction: "ltr",
    },
    "& li": {
      counterIncrement: "li",
      lineHeight: "2.4rem",
      marginRight: "2rem",
    },
  },
}))

const Iso_services = () => {
  const pageMeta = {
    title: "ISO",
    description: "خدمة تطبيق نظام إدارة امن المعلومات بمعيار آيزو",
    keywords: [
      "أمن المعلومات",
      "حماية أمنية",
      "خدمات أمنية",
      "شهادات أمنية معتمدة",
      "آيزو",
      "iso",
    ],
  }

  const classes = useStyles()
  const matches600 = useMediaQuery("(max-width: 700px)")

  return (
    <Layout isHero pageMeta={pageMeta}>
      <ThemeProvider theme={customTheme}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h1" paragraph className={classes.title}>
              معيار نظام إدارة أمن المعلومات آيزو 27001
            </Typography>
            <Typography className={classes.paragraph} paragraph>
              المعيار أيزو 27001 هو معيار دولي نشر بواسطة المنظمة الدولية
              للمعايير ( الايزو)، وهو معيار لوصف كيفية إدارة أمن المعلومات
              بالمؤسسة. آخر تنقيح لهذا المعيار نشر سنة 2013، والمعنون بـ ISO/IEC
              27001:2013 . النسخة الاولى من هذا المعيار نشرت بالعام 2005 , حيث
              طورت عن المعيار البريطاني BS 7799-2.
              <br /> <br />
              قوة المعيار تتمثل في تكيفه بحيث يمكن تطبيقه على جميع انواع
              المؤسسات , الربحية والغير ربحية، الخاصة أو المملوكة للدولة،
              المؤسسات الصغيرة والكبيرة. وقد كتب هذا المعيار بواسطة أفضل الخبراء
              الدوليين بمجال أمن المعلومات مزودا بمنهجية لتطبيق نظم إدارة أمن
              المعلومات بالمؤسسة.كما يمكن للمؤسسات أن تنال شهادة خاصة بتنفيذ
              المعيار (الالتزام) من خلال تدقيق طرف خارجي على صحة تنفيذ الالتزام
              بمتطلبات المعيار ما يعني ان جسم مستقل لمنح الشهادات يقر ان المؤسسة
              نفدت متطلبات إدارة أمن المعلومات بما يتلائم وهذا المعيار.
              <br /> <br />
              يحدد هذا المعيار متطلبات إنشاء، تنفيذ ، صيانة واستمرار تحسين نظام
              إدارة أمن المعلومات بالمؤسسة.
              <br /> <br />
              ويشمل هذا المعيار الدولي أيضا متطلبات تقييم وعلاج مخاطر أمن
              المعلومات وفقا لاحتياجات المؤسسة. المتطلبات الواردة في هذه
              المواصفة هي عامة ويقصد بها أن تنطبق على جميع المؤسسات، بغض النظر
              عن نوع وحجم أو طبيعة تلك المؤسسة. استبعاد أي من المتطلبات المحددة
              في الفقرات 4-10 غير مقبول عندما تدعي المؤسسة تبنيها لتطبيق المعيار
              الدولي
              <br /> <br />
              يركز المعيار أيزو 27001 على حماية معلومات المؤسسة على مستوى
              الخصوصية،السلامة والقدرة على الوصول. ينجز هذا العمل من خلال ايجاد
              المشاكل المحتملة التي يمكن ان تحدث للمعلومات (مايسمى تقييم الخطر
              )، ومن ثم تحديدالاحتياجات المطلوبة لمنع مثل هذه المشاكل من الحدوث
              (مايسمى تقليل المخاطر او معالجة المخاطر ). وبناء عليه فإن فلسفة
              المعيار أيزو 27001 تقوم على أساس ادارة المخاطر: ايجاد مكامن
              الاخطار، ومن ثم معالجتها بطريقة منهجية.
              <br /> <br />
              ستعمل شركة التميز للاستشارات وأمن المعلومات على تقديم المساعدة
              للمؤسسات الليبية فيما يخص تطبيق نظام ادارة أمن المعلومات من خلال
              مهندسيها لتغطية جميع المتطلبات الضرورية وتنفيذ ضوابط الحماية حسب
              ما سيتم سرده ضمن بيان التطابق بحيث يتم وضع أداوات الحماية في
              مكانها المناسب وضمن افضل ميزانية وتوقيت.
              <br /> <br />
              <strong>بحيث ستعمل شركة التميز على النقاط التالية:</strong>
            </Typography>
            <ol className={classes.list}>
              <li>التواصل مع الادارة العلية لدعم تطبيق النظام </li>
              <li>تحديد نطاق العمل </li>
              <li>كتابة السياسة العامة لنظام ادارة أمن المعلومات</li>
              <li>تحديد منهجية إدارة المخاطر</li>
              <li>إجراء تقييم ومعالجة المخاطر </li>
              <li>إعداد بيان التطابق</li>
              <li>كتابة خطة معالجة المخاطر </li>
              <li>تنفيذ الضوابط والاجراءات الاجبارية</li>
              <li>تنفيذ برامج التدريب والتوعية</li>
              <li>تحديد كيفية قياس فاعلية الضوابط</li>
              <li>تشغيل نظام إدارة أمن المعلومات</li>
              <li>مراقبة نظام إدارة امن المعلومات</li>
              <li>المساعدة في إجراءات وتنفيذ التدقيق الداخلي</li>
              <li>تقديم الادلة للادارة</li>
              <li>الاجراءات التصحيحية والوقائية</li>
            </ol>
          </Grid>
          <Grid item className={classes.sectionSeperator} />
          <Grid item container justify={matches600 ? "center" : "flex-start"}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                component="a"
                href="/contact"
              >
                تواصل معنا
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                component="a"
                href="/services"
              >
                عرض كل الخدمات
              </Button>
            </Grid>
          </Grid>
          <Grid item className={classes.sectionSeperator} />
          <Grid item className={classes.sectionSeperator} />
        </Grid>
      </ThemeProvider>
    </Layout>
  )
}

export default Iso_services
